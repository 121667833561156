import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBm_MtZ9IzdJrMX4I58P1ULB8VhvC14fa8",
    authDomain: "todoappredux-fbc26.firebaseapp.com",
    projectId: "todoappredux-fbc26",
    storageBucket: "todoappredux-fbc26.appspot.com",
    messagingSenderId: "847472896392",
    appId: "1:847472896392:web:e7e827a71fe46ec245723d",
    measurementId: "G-DBKTF9HLZQ"
  }; 

const app = firebase.initializeApp(firebaseConfig);

const db = app.firestore();
const auth = app.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export { db, auth, provider };
